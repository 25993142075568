import HttpClient from "../../../../../utils/httpClient";
import CryptoJS from "crypto-js";
import {
  generateMenu,
  setDifferent,
  callAPI,
  makeURL,
  getAPI,
  generateMenuVelectico,
  latLong,
} from "../../../../../scrap/generateMenu";
import axios from "axios";
import moment from "moment";

const removeSpecialCharacters = (inputString) => {
  // Define a regular expression pattern that matches any non-alphanumeric character excluding spaces
  var pattern = /[^a-zA-Z0-9\s]/g;

  // Use the replace() method to replace all occurrences of the pattern with an empty string
  var resultString = inputString.replace(pattern, "");

  return resultString;
};

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const generateEncryptedId = (input) => {
  const secretKey = CryptoJS.enc.Hex.parse("12345678901234567890123456789012"); // 256-bit key
  const iv = CryptoJS.enc.Hex.parse("1234567890123456"); // 128-bit IV
  const inputString = input.toString(); // Convert input to string if it's a number
  const encrypted = CryptoJS.AES.encrypt(inputString, secretKey, {
    iv: iv,
  }).toString();
  return encrypted;
};

const submitData = async (
  formInternalData,
  siteName,
  decodedValue,
  callback
) => {
  let loader = false;
  const baseString = "support+X";
  let randomNumber = getRandomInt(1, 100000);
  const email = `${baseString}${randomNumber}@orderbyte.io`;
  let resName = removeSpecialCharacters(formInternalData.restaurantName);
  let sheetName = "pqbulytxhm3vv";
  const loc = window.location.host;
  // sheetName =
  //   loc.includes("velectico") || loc.includes("localhost")
  //     ? "9q1yqqobsf61u"
  //     : sheetName;
  //   sheetName =
  //     loc.includes("velectico") || loc.includes("localhost")
  //       ? "ag3bmvtevjyoe"
  //       : sheetName;
  // console.log("sheetname ",sheetName)
  let different = setDifferent(formInternalData.restaurantWebsite);

  // const { lat, lng } = location.geometry.location;
  // console.log(lat(), lng());

  // const lati = lat();
  // const longi = lng();
  let store_id = "";

  let options = {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
    formatter = new Intl.DateTimeFormat([], options);
  let dateTime = formatter.format(new Date());
  let formateDateAndTime = moment(dateTime).format("L, h:mm A");

  let data = {
    restaurant_name: formInternalData.restaurantName.replace("'", ""),
    restaurant_type: formInternalData.restaurantType,
    //   website: restaurantWebsite,
    menu: formInternalData.restaurantWebsite,
    address: formInternalData.restaurantAddress,
    color: formInternalData.color,
    logo: "",
    customer_name: "",
    email: email,
    // email: "support+R387@orderbyte.io",
    phone: "+1" + "",
    domain_name: resName.replace(/\s+/g, "-").toLowerCase(),
    different: different,
    pizzabox: false,
    lati: formInternalData.latitude,
    longi: formInternalData.longitude,
  };
  loader = true;
  const resoData = await HttpClient.requestData("restogpt", "POST", data);
  let uid = "";

  // if (resoData) {
  //   setTimeout(() => {
  //      window.location.reload();
  //   }, 3000);
  // }
  // resoData.status

  if (resoData.menuId !== null || resoData.menuId !== "") {
    uid = generateEncryptedId(resoData.menuId);
    // console.log("uid",uid);
  }

  if (different && resoData && resoData.menuId !== 0 && resoData.userId !== 0) {
    let menuUrl = await makeURL(formInternalData.restaurantWebsite);

    const restObj = {
      url: menuUrl,
      // titleUrl: restaurantLogo,
      coverUrl: "",
      brandName: formInternalData.restaurantName.replace("'", ""),
      description: "",
      userId: resoData.userId,
      lati: formInternalData.latitude,
      longi: formInternalData.longitude,
      menuId: resoData.menuId,
      store_id: store_id,
      email: email,
      uid: uid,
      pizzabox: false,
      isAddonsEnable: false,
      isRegenerate: false,
      parser: "roma", //false in main page but true in regeneration button
    };
    // setShowLoading(true);
    let d = {};
    if (
      menuUrl.includes("order.chownow.com") ||
      menuUrl.includes("direct.chownow.com") ||
      menuUrl.includes("chownow.com")
    ) {
      d = await generateMenuVelectico(restObj);
    } else {
      d = await generateMenu(restObj);
    }

    if (d.status === 1) {
    }
  } else {
  }
  let sheetsData = new FormData();
  sheetsData.append("Restaurant_Login", email);
  sheetsData.append("Restaurant_Password", "$GruYiiUaU13");
  sheetsData.append("Menu_Link", formInternalData.restaurantWebsite);
  sheetsData.append("Website_Link", "");
  sheetsData.append("Customer_Name", "");
  sheetsData.append("Address", formInternalData.restaurantAddress);
  sheetsData.append("Requested_Date_Time_(PST)", formateDateAndTime);
  sheetsData.append("RESTAURANT_NAME", formInternalData.restaurantName);
  sheetsData.append("PHONE_NUMBER", "");
  sheetsData.append("Logo", "");
  sheetsData.append("uid", uid);
  sheetsData.append("generation_status", "generating");
  sheetsData.append("Menu_id", resoData.menuId);
  sheetsData.append("reason", "");

  if (resoData && resoData.storefront_link) {
    let store_link = `https://restogpt.ai/${resoData.storefront_link}`;
    sheetsData.append("STOREFRONT_LINK", store_link);
  } else {
    sheetsData.append("STOREFRONT_LINK", "");
  }

  if (resoData) {
    axios
      .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
      .then((response) => {
        console.log("response", response);
        if (response.status === 201) {
          loader = false;
          callback(loader);
          // window.location.reload();
        }
      });
  }
};

export default submitData;
