import React from "react";
import ResName from "./ResName";
import ResType from "./ResType";
import ResWebsite from "./ResWebsite";
import ResMenu from "./ResMenu";
import ResAddress from "./ResAddress";
import ResColor from "./ResColor";
import ResLogo from "./ResLogo";
import Name from "./Name";
import Email from "./Email";
import Number from "./Number";
import HearAbout from "./HearAbout";
import TypewriterEffect from "./TypewriterEffect";
import HttpClient from "../utils/httpClient";
import Tick from "../images/tick.svg";
import BtnTick from "../images/btn-tick.png";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../images/close-icon.png";
import RightIcon from "../images/right.png";
import EnterIcon from "../images/enter-icon.svg";
import axios from "axios";
import moment from "moment";
import Loader from "./Loader";
import { TropicalStorm } from "react-bootstrap-icons";
// import { AES, enc } from 'crypto-js';
import CryptoJS from "crypto-js";
import {
  generateMenu,
  setDifferent,
  callAPI,
  makeURL,
  getAPI,
  generateMenuVelectico,
  latLong,
} from "../scrap/generateMenu";
// import useWindowResize from "./hooks/onbording/static/useWindowResize";
import useWindowResize from "../onbording/hooks/onbording/static/useWindowResize";
// import moment from "moment-timezone";

const Form = ({ isLoading, showCircles }) => {
  const { screenWidth } = useWindowResize();

  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [menuMsg, setMenuMsg] = React.useState(
    "Please wait... while generating your site"
  );
  const [location, setLocation] = React.useState([]);

  const [showLoading, setShowLoading] = React.useState(false);
  const [switchField, setSwitchField] = React.useState("next");
  const [restaurantName, setRestaurantName] = React.useState("");
  const [restaurantType, setRestaurantType] = React.useState("");
  const [restaurantWebsite, setRestaurantWebsite] = React.useState("");
  const [restaurantMenu, setRestaurantMenu] = React.useState("");
  const [restaurantAddress, setRestaurantAddress] = React.useState("");
  const [isRestaurantAddressSelected, setIsRestaurantAddressSelected] =
    React.useState(false);
  const [restaurantColor, setRestaurantColor] = React.useState("");
  const [restaurantLogo, setRestaurantLogo] = React.useState("");
  const [restaurantLogoRequired, setRestaurantLogoRequired] =
    React.useState(false);
  const [restaurantLogoName, setRestaurantLogoName] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const [hearAbout, setHearAbout] = React.useState("");
  const [hearAboutReferrer, setHearAboutReferrer] = React.useState("");
  const [hearAboutOther, setHearAboutOther] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [modalTextDelay, setModalTextDelay] = React.useState("");
  const [btnActive, setBtnActive] = React.useState(false);
  const [progressCount, setProgressCount] = React.useState(0);
  const [randomWaitingTime, setRandomWaitingTime] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [emailBtn, setEmailBtn] = React.useState(false);
  const [generateBtn, setGenerateBtn] = React.useState(false);
  const [existMenu, setExistMenu] = React.useState("");
  // console.log(process.env.REACT_APP_SITE_KEY)
  React.useEffect(() => {
    loadCurrentFormComponent();
    getAPI();
  }, []);

  const getRandomNumber = () => {
    const min = 60;
    const max = 90;
    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomWaitingTime(randomNumber);
    countdown(randomNumber);
    setTimeout(() => {
      showNextTextInModal();
    }, 6000);
  };

  function countdown(randomNumber) {
    let minutePassed = 0;

    setInterval(() => {
      let progress = (100 / randomNumber) * minutePassed;
      ++minutePassed;
      setProgressCount(progress);
      if (progress > randomNumber) {
        return;
      }
      // console.log(progress);
    }, 5000);
  }

  const textsDown = [
    "Due to high demand, processing times are longer. <span> We will send you an email when</span> your online ordering storefront is ready.",
    "You can safely leave this page!",
    "With ❤️ RestoGPT",
  ];

  const textsUp = [
    `Fetching restaurant menu…`,
    `Processing and analyzing… <span>15%</span>`,
    `Estimated waiting time: <span>~ ${randomWaitingTime} mins</span>`,
  ];

  const handleClose = () => {
    setShow(false);
    setSwitchField("next");
    setRestaurantName("");
    // localStorage.removeItem("question");
    isLoading(true);
  };

  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      getRandomNumber();
    }, 1000);
  };

  const handleCloseError = () => {
    setShowError(false);
    setSwitchField("next");
    setRestaurantName("");
    isLoading(true);
    localStorage.removeItem("hear_about");
    localStorage.removeItem("hearAboutOther");
    localStorage.removeItem("hearAboutReferrer");
    localStorage.removeItem("restaurant_type");
    localStorage.removeItem("longi");
  };

  const handleShowError = () => setShowError(true);

  const loadCurrentFormComponent = () => {
    if (localStorage && localStorage.getItem("question") != null) {
      let question = localStorage.getItem("question");
      question = JSON.parse(question);
      Object.keys(question).forEach((key) => {
        setValuesFromLocalStorage(key, question[key]);
      });
      let lastKey = Object.keys(question).reverse()[0];
      setSwitchField(lastKey);
    }
  };

  const setValuesFromLocalStorage = (step, value) => {
    let referrerValue = localStorage.getItem("hearAboutReferrer");
    switch (step) {
      case "next":
        setRestaurantName(value);
        break;

      case "next1":
        setRestaurantType(value);
        break;
      case "next2":
        setRestaurantWebsite(value);
        break;
      case "next3":
        setRestaurantMenu(value);
        break;
      case "next4":
        setRestaurantAddress(value);
        break;
      case "next5":
        let color = localStorage.getItem("color");
        setRestaurantColor(color);
        break;
      case "next6":
        let trimValue = value.replace(
          "https://orderbyte.io/api/v1/download/gpt/",
          ""
        );
        setRestaurantLogo(value);
        setRestaurantLogoName(trimValue);
        break;
      case "next7":
        setFullName(value);
        break;
      case "next8":
        setEmail(value);
        break;
      case "next9":
        setPhone(value);
        break;
      case "next10":
        setHearAbout(value);
        setHearAboutReferrer(referrerValue);
        setHearAboutOther(referrerValue);
        break;

      default:
        break;
    }
  };

  const removeSpecialCharacters = (inputString) => {
    // Define a regular expression pattern that matches any non-alphanumeric character excluding spaces
    var pattern = /[^a-zA-Z0-9\s]/g;

    // Use the replace() method to replace all occurrences of the pattern with an empty string
    var resultString = inputString.replace(pattern, "");

    return resultString;
  };

  const generateEncryptedId = (input) => {
    const secretKey = CryptoJS.enc.Hex.parse(
      "12345678901234567890123456789012"
    ); // 256-bit key
    const iv = CryptoJS.enc.Hex.parse("1234567890123456"); // 128-bit IV
    const inputString = input.toString(); // Convert input to string if it's a number
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey, {
      iv: iv,
    }).toString();
    return encrypted;
  };

  const formUpload = async () => {
    let resName = removeSpecialCharacters(restaurantName);
    let hearAboutValue = "";
    if (hearAbout === "Referral") {
      hearAboutValue = hearAboutReferrer;
    } else if (hearAbout === "Other") {
      hearAboutValue = hearAboutOther;
    } else {
      hearAboutValue = hearAbout;
    }
    let sheetName = "pqbulytxhm3vv";
    const loc = window.location.host;
    // sheetName =
    //   loc.includes("velectico") || loc.includes("localhost")
    //     ? "9q1yqqobsf61u"
    //     : sheetName;
    sheetName =
      loc.includes("velectico") || loc.includes("localhost")
        ? "ag3bmvtevjyoe"
        : sheetName;
    // console.log("sheetname ",sheetName)
    let different = setDifferent(restaurantMenu);

    // if (localStorage && localStorage.getItem("lati") != null) {
    //   lati = localStorage.getItem("lati");
    // }
    // if (localStorage && localStorage.getItem("longi") != null) {
    //   longi = localStorage.getItem("longi");
    // }

    const { lat, lng } = location.geometry.location;
    console.log(lat(), lng());

    const lati = lat();
    const longi = lng();
    let store_id = "";
    // let resLatLong={}
    if (different === false) {
      //&& restaurantName.includes('mealme')) // then mealme
      //  resLatLong = await latLong(restaurantAddress.trim())
      let mealMeObj = {
        brandName: restaurantName,
        lati,
        longi,
        address: restaurantAddress,
      };
      const meal = await callAPI(mealMeObj);
      if (meal.status === 1) {
        //====== need to uncomment when we get the live mealme api key
        store_id = meal.store_id;
        different = meal.different;
      }
    }

    let options = {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      formatter = new Intl.DateTimeFormat([], options);
    let dateTime = formatter.format(new Date());
    let formateDateAndTime = moment(dateTime).format("L, h:mm A");

    let phone_enter = "";
    let phoneLocal = localStorage.getItem("phone");
    phone_enter =
      localStorage && localStorage.getItem("phone") !== null
        ? phoneLocal
        : phone;

    let data = {
      restaurant_name: restaurantName.replace("'", ""),
      restaurant_type: restaurantType,
      website: restaurantWebsite,
      menu: restaurantMenu,
      address: restaurantAddress,
      color: restaurantColor,
      logo: restaurantLogo,
      customer_name: fullName,
      email: email,
      phone: "+1" + phone_enter,
      domain_name: resName.replace(/\s+/g, "-").toLowerCase(),
      different: different,
      hear_about: hearAboutValue,
      pizzabox: false,
      lati: lati,
      longi: longi,
    };

    console.log("data", data);

    const resoData = await HttpClient.requestData("restogpt", "POST", data);
    let uid = "";

    // if (resoData.userId != null || resoData.userId != "") {
    //   handleShow();
    // }
    // resoData.status

    if (resoData.menuId !== null || resoData.menuId !== "") {
      uid = generateEncryptedId(resoData.menuId);
      // console.log("uid",uid);
    }

    if (different) {
      setExistMenu("exist");
      let menuUrl = await makeURL(restaurantMenu);

      const restObj = {
        url: menuUrl,
        titleUrl: restaurantLogo,
        coverUrl: "",
        brandName: restaurantName.replace("'", ""),
        description: "",
        userId: resoData.userId,
        lati: lati,
        longi: longi,
        menuId: resoData.menuId,
        store_id: store_id,
        email: email,
        uid: uid,
        pizzabox: false,
        isAddonsEnable: false, //false in main page but true in regeneration button
        isRegenerate: false,
        parser: "roma",
      };
      // setShowLoading(true);
      let d = {};
      if (
        menuUrl.includes("order.chownow.com") ||
        menuUrl.includes("direct.chownow.com") ||
        menuUrl.includes("chownow.com")
      ) {
        d = await generateMenuVelectico(restObj);
      } else {
        d = await generateMenu(restObj);
      }

      if (d.status === 1) {
        // setMenuMsg("Your storefront is generated successfully");
        setGenerateBtn(false);
        setTimeout(() => {
          // handleShow();
          // showNextTextInModal();
        }, 1000);

        // setTimeout(()=>{
        //   setShowLoading(false)
        // },3000)
      } else {
        setMenuMsg("Internal error occurred");
      }
    } else {
      setExistMenu("noExist");
      setGenerateBtn(false);
      // handleShow();
      // setTimeout(() => {
      //   handleShow();
      //   // showNextTextInModal();
      // }, 1000);
    }
    // handleShow();

    let sheetsData = new FormData();
    sheetsData.append("Restaurant_Login", email);
    sheetsData.append("Restaurant_Password", "$GruYiiUaU13");
    sheetsData.append("Menu_Link", restaurantMenu);
    sheetsData.append("Website_Link", restaurantWebsite);
    sheetsData.append("Customer_Name", fullName);
    sheetsData.append("Address", restaurantAddress);
    sheetsData.append("Requested_Date_Time_(PST)", formateDateAndTime);
    sheetsData.append("RESTAURANT_NAME", restaurantName);
    sheetsData.append("PHONE_NUMBER", phone_enter);
    sheetsData.append("Logo", restaurantLogo);
    sheetsData.append("HEAR_FROM", hearAboutValue);
    sheetsData.append("uid", uid);
    sheetsData.append("generation_status", "generating");
    sheetsData.append("Menu_id", resoData.menuId);
    sheetsData.append("reason", "");

    axios
      .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
      .then((response) => {});

    setRestaurantName("");
    setRestaurantWebsite("");
    setRestaurantMenu("");
    setRestaurantAddress("");
    setRestaurantColor("");
    setRestaurantLogo("");
    setRestaurantLogoName("");
    setFullName("");
    setEmail("");
    setPhone("");
    localStorage.removeItem("question");
    localStorage.removeItem("color");
    localStorage.removeItem("restaurant_type");
    localStorage.removeItem("lati");
    localStorage.removeItem("longi");
    setTimeout(() => {
      localStorage.removeItem("phone");
      localStorage.removeItem("hear_about");
      localStorage.removeItem("hearAboutOther");
      localStorage.removeItem("hearAboutReferrer");
    }, 2000);
  };
  // const formUpload = async () => {

  //   let sheetName='pqbulytxhm3vv'
  //   const loc = window.location.host
  //   sheetName = (loc.includes('velectico') || loc.includes('localhost') ) ? "9q1yqqobsf61u" : sheetName;
  //   console.log("sheetname ",sheetName)
  //   let different = setDifferent(restaurantMenu);

  //   const { lat, lng } = location.geometry.location;
  //   console.log(lat(), lng());

  //     const lati=lat();
  //     const longi=lng();
  //     let store_id=""
  //     if(different===false && restaurantName.includes('mealme')) // then mealme
  //     {
  //       let mealMeObj={
  //           "brandName":restaurantName,
  //           lati,
  //           longi,
  //       }
  //       const meal=await callAPI(mealMeObj)
  //       if(meal.status===1)
  //       {
  //         //====== need to uncomment when we get the live mealme api key
  //        store_id=meal.store_id
  //         different=meal.different
  //       }
  //     }

  //   let options = {
  //       timeZone: "America/Los_Angeles",
  //       year: "numeric",
  //       month: "numeric",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //     },
  //     formatter = new Intl.DateTimeFormat([], options);
  //   let dateTime = formatter.format(new Date());
  //   let formateDateAndTime = moment(dateTime).format("MMMM Do YYYY, h:mm a");

  //   let phone_enter = "";
  //   if (localStorage && localStorage.getItem("phone") != null) {
  //     let phone = localStorage.getItem("phone");
  //     phone_enter = phone;

  //     let data = {
  //       restaurant_name: restaurantName,
  //       restaurant_type: restaurantType,
  //       website: restaurantWebsite,
  //       menu: restaurantMenu,
  //       address: restaurantAddress,
  //       color: restaurantColor,
  //       logo: restaurantLogo,
  //       customer_name: fullName,
  //       email: email,
  //       phone: "+1" + phone_enter,
  //       different: different,
  //     };

  //     let sheetsData = new FormData();
  //     sheetsData.append("Restaurant_Login", email);
  //     sheetsData.append("Restaurant_Password", "$GruYiiUaU13");
  //     sheetsData.append("Menu_Link", restaurantMenu);
  //     sheetsData.append("Website_Link", restaurantWebsite);
  //     sheetsData.append("Customer_Name", fullName);
  //     sheetsData.append("Address", restaurantAddress);
  //     sheetsData.append("Requested_Date_Time_(PST)", formateDateAndTime);
  //     sheetsData.append("RESTAURANT_NAME", restaurantName);
  //     sheetsData.append("PHONE_NUMBER", phone);

  //     const resoData = await HttpClient.requestData("restogpt", "POST", data);
  //     if (different) {

  //       let menuUrl=restaurantMenu;
  //       if(!menuUrl.startsWith('https://'))
  //       {
  //         menuUrl = 'https://'+menuUrl
  //       }
  //       let url = new URL(menuUrl);
  //       console.log(url.origin + url.pathname);
  //       menuUrl=url.origin + url.pathname

  //       const restObj = {
  //         url: menuUrl,
  //         titleUrl: restaurantLogo,
  //         coverUrl: "",
  //         brandName: restaurantName,
  //         description: "",
  //         userId: resoData.userId,
  //         lati: lat(),
  //         longi: lng(),
  //         menuId: resoData.menuId,
  //         store_id:store_id
  //       };
  //       setShowLoading(true);
  //       const d = await generateMenu(restObj);

  //       if (d.status === 1) {
  //         setMenuMsg("Your storefront is generated successfully");
  //         // setTimeout(()=>{
  //         //   setShowLoading(false)
  //         // },3000)
  //       } else {
  //         setMenuMsg("Internal error occurred");
  //       }
  //     } else {
  //       handleShow();
  //     }

  //     axios
  //       .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
  //       .then((response) => {});
  //   } else {

  //     let data = {
  //       restaurant_name: restaurantName,
  //       restaurant_type: restaurantType,
  //       website: restaurantWebsite,
  //       menu: restaurantMenu,
  //       address: restaurantAddress,
  //       color: restaurantColor,
  //       logo: restaurantLogo,
  //       customer_name: fullName,
  //       email: email,
  //       phone: "+1" + phone,
  //       different: different,
  //     };
  //     let sheetsData = new FormData();
  //     sheetsData.append("Restaurant_Login", email);
  //     sheetsData.append("Restaurant_Password", "$GruYiiUaU13");
  //     sheetsData.append("Menu_Link", restaurantMenu);
  //     sheetsData.append("Website_Link", restaurantWebsite);
  //     sheetsData.append("Customer_Name", fullName);
  //     sheetsData.append("Address", restaurantAddress);
  //     sheetsData.append("Requested_Date_Time_(PST)", formateDateAndTime);
  //     sheetsData.append("RESTAURANT_NAME", restaurantName);
  //     sheetsData.append("PHONE_NUMBER", phone);
  //     //==================== by prashanta ===========
  //     const resoData = await HttpClient.requestData("restogpt", "POST", data);
  //     if (different) {
  //       const { lat, lng } = location.geometry.location;
  //       console.log(lat(), lng());

  //       let menuUrl=restaurantMenu;
  //       if(!menuUrl.startsWith('https://'))
  //       {
  //         menuUrl = 'https://'+menuUrl
  //       }
  //       let url = new URL(menuUrl);
  //       console.log(url.origin + url.pathname);
  //       menuUrl=url.origin + url.pathname

  //       const restObj = {
  //         url: menuUrl,
  //         titleUrl: restaurantLogo,
  //         coverUrl: "",
  //         brandName: restaurantName,
  //         description: "",
  //         userId: resoData.userId,
  //         lati: lat(),
  //         longi: lng(),
  //         menuId: resoData.menuId,
  //         store_id:store_id
  //       };
  //       setShowLoading(true);
  //       const d = await generateMenu(restObj);

  //       if (d.status === 1) {
  //         setMenuMsg("Your storefront is generated successfully");
  //         setTimeout(() => {
  //           setShowLoading(false);
  //         }, 3000);
  //       } else {
  //         setMenuMsg("Internal error occurred");
  //       }
  //     } else {
  //       handleShow();
  //     }

  //     //==================== END===========

  //     axios
  //       .post(`https://sheetdb.io/api/v1/${sheetName}`, sheetsData)
  //       .then((response) => {});
  //   }

  //   setRestaurantName("");
  //   setRestaurantWebsite("");
  //   setRestaurantMenu("");
  //   setRestaurantAddress("");
  //   setRestaurantColor("");
  //   setRestaurantLogo("");
  //   setRestaurantLogoName("");
  //   setFullName("");
  //   setEmail("");
  //   setPhone("");
  //   localStorage.removeItem("question");
  //   localStorage.removeItem("color");
  //   setTimeout(() => {
  //     localStorage.removeItem("phone");
  //   }, 2000);
  // };

  const handleKeypress = (e, current, next) => {
    console.log("handleKeypress", current, next);
    if (e.key === "Enter") {
      checkAndProceed(current, next);
    }
    // if (e.key === "Enter" && current === "next10") {
    //   setSwitchField("next10");
    //   checkAndProceed(current, next);
    // }
  };

  const checkAndProceed = async (current, next) => {
    let isAllowed = await isAllowedToProceed(current);
    if (isAllowed) {
      setSwitchField(next);
    }
    if (isAllowed && current === "next10") {
      setSwitchField("next10");
    }
  };

  const isAllowedToProceed = async (current) => {
    let isAllowed = true;
    switch (current) {
      case "next":
        if (restaurantMenu === "") {
          isAllowed = false;
          setErrorMessage("* Please enter this field to proceed");
        } else {
          isAllowed = true;
          storeToLocalStorage(current, restaurantMenu);
          setErrorMessage("");
        }
        break;

        case "next1":
          if (restaurantName === "") {
            isAllowed = false;
            setErrorMessage("* Please enter this field to proceed");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, restaurantName);
            setErrorMessage("");
          }
          break;

      case "next2":
        if (localStorage && localStorage.getItem("restaurant_type") != null) {
          let restaurantTypeFrom = localStorage.getItem("restaurant_type");
          if (restaurantTypeFrom === "") {
            isAllowed = false;
            setErrorMessage("* Please select restaurant typeeee");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, restaurantTypeFrom);
            setErrorMessage("");
          }
        } else {
          if (restaurantType === "") {
            isAllowed = false;
            setErrorMessage("* Please select restaurant type");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, restaurantType);
            setErrorMessage("");
          }
        }
        break;

      case "next3":
        storeToLocalStorage(current, restaurantWebsite);
        break;

      // case "next4":
      //   if (restaurantMenu === "") {
      //     isAllowed = false;
      //     setErrorMessage("* Please enter this field to proceed");
      //   } else {
      //     isAllowed = true;
      //     storeToLocalStorage(current, restaurantMenu);
      //     setErrorMessage("");
      //   }
      //   break;

      case "next4":
        if (restaurantAddress === "") {
          isAllowed = false;
          setErrorMessage("* Please enter this field to proceed");
        } else if (isRestaurantAddressSelected === false) {
          isAllowed = false;
          setErrorMessage("Please select address");
        } else {
          isAllowed = true;
          const { lat, lng } = location.geometry.location;
          const lati = lat();
          const longi = lng();
          localStorage.setItem("lati", lati);
          localStorage.setItem("longi", longi);
          storeToLocalStorage(current, restaurantAddress);
          setErrorMessage("");
        }
        break;

      case "next5":
        if (localStorage && localStorage.getItem("color") != null) {
          let color = localStorage.getItem("color");
          if (color === "") {
            isAllowed = false;
            setErrorMessage("* Please select any color");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, color);
            setErrorMessage("");
          }
        } else {
          if (restaurantColor === "") {
            isAllowed = false;
            setErrorMessage("* Please select any color");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, restaurantColor);
            setErrorMessage("");
          }
        }
        break;

      case "next6":
        if (localStorage && localStorage.getItem("logo") != null) {
          let logo = localStorage.getItem("logo");
          storeToLocalStorage(current, logo);
        } else {
          storeToLocalStorage(current, restaurantLogo);
        }
        break;

      case "next7":
        if (fullName === "") {
          isAllowed = false;
          setErrorMessage("* Please enter this field to proceed");
        } else {
          isAllowed = true;
          storeToLocalStorage(current, fullName);
          setErrorMessage("");
        }
        break;

      case "next8":
        let emailValid =
          /[\w+-]+(?:\.[\w+-]+)*@[\w+-]+(?:\.[\w+-]+)*(?:\.[a-zA-Z]{1,4})/.test(
            email
          );
        if (email === "") {
          isAllowed = false;
          setErrorMessage("* Please enter this field to proceed");
        } else if (!emailValid) {
          isAllowed = false;
          setErrorMessage("* Please Add Valid Email Address");
        } else if ((await checkEmailExist()) === false) {
          isAllowed = false;
          setErrorMessage(
            "* Sorry, this email address has already been used. Please use a different one."
          );
        } else if ((await checkEmailExistInReal()) === "FALSE") {
          isAllowed = false;
          setErrorMessage(
            "* This mail does not exist, please enter an existing mail."
          );
        } else {
          isAllowed = true;
          storeToLocalStorage(current, email);
          setErrorMessage("");
          // finalSubmit();
        }
        break;

      case "next9":
        if (phone === "") {
          isAllowed = false;
          setErrorMessage("* Please enter this field to proceed");
        } else {
          isAllowed = true;
          storeToLocalStorage(current, phone);
          setErrorMessage("");
          // finalSubmit();
        }
        break;

      case "next10":
        if (localStorage && localStorage.getItem("hear_about") != null) {
          let hearAboutFrom = localStorage.getItem("hear_about");
          if (hearAboutFrom === "") {
            isAllowed = false;
            setErrorMessage("* Please select anyone");
          } else if (hearAboutFrom === "Referral" && hearAboutReferrer === "") {
            isAllowed = false;
            setErrorMessage("* Please enter this field to proceed");
          } else if (hearAboutFrom === "Other" && hearAboutOther === "") {
            isAllowed = false;
            setErrorMessage("* Please enter this field to proceed");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, hearAboutFrom);
            setErrorMessage("");
            finalSubmit();
          }
        } else {
          if (hearAbout === "") {
            isAllowed = false;
            setErrorMessage("* Please select anyone");
          } else {
            isAllowed = true;
            storeToLocalStorage(current, hearAbout);
            setErrorMessage("");
            finalSubmit();
          }
        }
        break;

      default:
        setErrorMessage("");
        break;
    }

    return isAllowed;
  };

  const storeToLocalStorage = (current, value) => {
    let question = {};
    if (localStorage && localStorage.getItem("question") != null) {
      question = localStorage.getItem("question");
      question = JSON.parse(question);
    }
    question[current] = value;
    localStorage.setItem("question", JSON.stringify(question));
  };

  const checkEmailExist = async () => {
    let status = true;
    let data = {
      email: email,
    };
    setEmailBtn(true);
    let result = await HttpClient.requestData(
      "retogpt_verify_email",
      "POST",
      data
    );
    if (result) {
      setEmailBtn(false);
    }
    if (result.status === "001") {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  const checkEmailExistInReal = async () => {
    setEmailBtn(true);
    let api_key = process.env.REACT_APP_EMAIL_CHECK;
    let url = `https://emailvalidation.abstractapi.com/v1/?api_key=${api_key}&email=${email}`;
    const response = await fetch(url);
    const data = await response.json();
    if (data) {
      setEmailBtn(false);
      setSwitchField("next8");
    }
    return data.is_smtp_valid.text;
  };

  const finalSubmit = () => {
    // setLoading(true);
    // setBtnActive(true);
    // setGenerateBtn(true);
    // formUpload();
    // handleShow();
    // getRandomNumber();

    getReCaptchaToken();

    // showNextTextInModal();
  };

  const showNextTextInModal = () => {
    return setTimeout(() => {
      setModalTextDelay("done");
    }, 12000);
  };

  const getReCaptchaToken = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY, { action: "submit" })
        .then((token) => {
          googleReCaptchaToken(token);
        });
    });
  };

  async function googleReCaptchaToken(token) {
    if (token) {
      let result = await HttpClient.requestData(
        "siteverify?token=" + token,
        "POST"
      );
      if (result.success) {
        formUpload();
        handleShow();
      } else {
        handleShowError();
        localStorage.removeItem("question");
      }
    }
  }

  return (
    <>
      {/* <button onClick={handleShow}>click</button> */}
      {switchField === "next" ? (
        <>
          <ResMenu
            restaurantMenu={restaurantMenu}
            setRestaurantMenu={setRestaurantMenu}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next"
            next="next1"
            showCircles={showCircles}
          />
          <div className="btn-box">
            {/* <button className="back-btn"> &lt; back</button> */}
            {/* <div className="back-btn"></div> */}
            <button
              className="back-btn"
              style={{ visibility: "hidden" }}
              onClick={() => setSwitchField("next")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next", "next1");
                }}
              >
                OK <img src={BtnTick} alt="logo btn-tick" />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next1" ? (
        <>
          <ResName
            restaurantName={restaurantName}
            setRestaurantName={setRestaurantName}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next1"
            next="next2"
          />
          <div className="btn-box">
            <button className="back-btn" onClick={() => setSwitchField("next")}>
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next1", "next2");
                }}
              >
                OK <img src={BtnTick} alt="logo" />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next2" ? (
        <>
          <ResType
            restaurantType={restaurantType}
            setRestaurantType={setRestaurantType}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next2"
            next="next3"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next1")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next2", "next3");
                }}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next3" ? (
        <>
          <ResWebsite
            restaurantWebsite={restaurantWebsite}
            setRestaurantWebsite={setRestaurantWebsite}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next3"
            next="next4"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next2")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next3", "next4");
                }}
                // disabled={restaurantMenu === "" ? true : false}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next4" ? (
        <>
          <ResAddress
            restaurantAddress={restaurantAddress}
            setRestaurantAddress={setRestaurantAddress}
            setIsRestaurantAddressSelected={setIsRestaurantAddressSelected}
            isRestaurantAddressSelected={isRestaurantAddressSelected}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next4"
            next="next5"
            setLocation={setLocation}
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next3")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next4", "next5");
                }}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next5" ? (
        <>
          <ResColor
            restaurantColor={restaurantColor}
            setRestaurantColor={setRestaurantColor}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next5"
            next="next6"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next4")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next5", "next6");
                }}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next6" ? (
        <>
          <ResLogo
            restaurantLogo={restaurantLogo}
            setRestaurantLogo={setRestaurantLogo}
            restaurantLogoRequired={restaurantLogoRequired}
            setRestaurantLogoRequired={setRestaurantLogoRequired}
            restaurantLogoName={restaurantLogoName}
            setRestaurantLogoName={setRestaurantLogoName}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next6"
            next="next7"
          />
          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next5")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                id="btn_clkd"
                onClick={() => checkAndProceed("next6", "next7")}
                // disabled={restaurantLogo === "" ? true : false}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next7" ? (
        <>
          <Name
            fullName={fullName}
            setFullName={setFullName}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next7"
            next="next8"
          />
          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next6")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next7", "next8");
                }}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next8" ? (
        <>
          <Email
            email={email}
            setEmail={setEmail}
            setEmailValid={setEmailValid}
            emailValid={emailValid}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            handleKeypress={handleKeypress}
            current="next8"
            next="next9"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => setSwitchField("next7")}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              {/* <button
              // className={emailBtn === true ? "disable-btn" : "ok-btn"}
              className="ok-btn"
              onClick={() => {
                checkAndProceed("next8", "next9");
              }}
              // disabled={emailBtn === true ? true : false}
            >
              OK <img src={BtnTick} />
            </button> */}
              {emailBtn ? (
                <button className="ok-btn">
                  <TropicalStorm className="btn-loader" /> Loading...  
                </button>
              ) : (
                <button
                  // className={emailBtn === true ? "disable-btn" : "ok-btn"}
                  className="ok-btn"
                  onClick={() => {
                    checkAndProceed("next8", "next9");
                  }}
                  // disabled={emailBtn === true ? true : false}
                >
                  OK <img src={BtnTick} />
                  {/* <TropicalStorm className="btn-loader" /> Loading...   */}
                </button>
              )}
            </div>
          </div>
        </>
      ) : null}

      {switchField === "next9" ? (
        <>
          <Number
            phone={phone}
            setPhone={setPhone}
            handleKeypress={handleKeypress}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            current="next9"
            next="next10"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => {
                setSwitchField("next8");
              }}
              style={{ marginRight: "10px" }}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              <button
                className="ok-btn"
                onClick={() => {
                  checkAndProceed("next9", "next10");
                }}
              >
                OK <img src={BtnTick} />
              </button>
            </div>
          </div>
          <div></div>
        </>
      ) : null}

      {switchField === "next10" || switchField === undefined ? (
        <>
          <HearAbout
            hearAbout={hearAbout}
            setHearAbout={setHearAbout}
            hearAboutReferrer={hearAboutReferrer}
            setHearAboutReferrer={setHearAboutReferrer}
            hearAboutOther={hearAboutOther}
            setHearAboutOther={setHearAboutOther}
            handleKeypress={handleKeypress}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            current="next10"
          />

          <div className="btn-box">
            <button
              className="back-btn"
              onClick={() => {
                setSwitchField("next9");
              }}
              style={{ marginRight: "10px" }}
            >
              {" "}
              &lt; back
            </button>
            <div className="right">
              <p style={{ marginLeft: "15px" }}>
                press <span>Enter</span> <img src={EnterIcon} />
              </p>
              {generateBtn ? (
                <button className="ok-btn gen-btn">
                  <TropicalStorm className="btn-loader" /> Loading...  
                </button>
              ) : (
                <button
                  className="ok-btn gen-btn"
                  // onClick={finalSubmit}
                  onClick={() => {
                    checkAndProceed("next10", "");
                  }}
                  disabled={btnActive ? true : false}
                >
                  GENERATE <img src={Tick} alt="tik" />
                </button>
              )}
            </div>
          </div>
          <div></div>
        </>
      ) : null}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="AI-modal formPopup"
        // dialogClassName="modal-90w"
        backdrop="static"
        // style={{ maxWidth: "800%" }}
      >
        <Modal.Body>
          <button onClick={handleClose} className="close-btn">
            <img src={CloseIcon} />
          </button>
          <h4>
            Your storefront is being generated ✅{" "}
            {/* <img src={Tick} alt="logo" className="tick-icon" /> */}
          </h4>
          {/* <h2>Your storefront is being generated</h2> */}
          {/* <div className="css-typing">
            <li>Fetching restaurant menu…</li>
            <li>
              Processing and analyzing… <span>15%</span>
            </li>
            <li className="hide-right-border">
              Estimated waiting time: <span>~ {randomWaitingTime} mins</span>
            </li>
          </div> */}
          <TypewriterEffect modalTextDelay1="done" texts={textsUp} />
          <div className="timerProgress">
            <div className="bar" style={{ width: `${progressCount}%` }}></div>
          </div>
          <br />

          {modalTextDelay === "done" ? (
            <>
              <TypewriterEffect
                modalTextDelay1={modalTextDelay}
                texts={textsDown}
                up={true}
              />
            </>
          ) : null}
        </Modal.Body>
      </Modal>

      <Modal
        show={showError}
        onHide={handleCloseError}
        centered
        size="lg"
        className="AI-modal"
        backdrop="static"
      >
        <Modal.Body>
          <button onClick={handleCloseError} className="close-btn">
            <img src={CloseIcon} />
          </button>

          <h3>Please Mention You Are Not A Bot!</h3>
          {/* <ul>
            <li>You can safely leave this page!</li>
            <li>With ❤️ - RestoGPT</li>
          </ul> */}
          <button style={{ display: "none" }} onClick={formUpload}>
            {" "}
            Gen{" "}
          </button>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showLoading}
        onHide={handleCloseError}
        centered
        size="lg"
        className="popup"
        backdrop="static"
      >
        <Modal.Body>
          <button onClick={handleCloseError} className="closeBtn">
            <img src={CloseIcon} />
          </button>

          <h2>{menuMsg}</h2>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Form;
